<template>
  <div class="contact d-flex justify-content-center">
    <b-container>
    <b-row>
      <!-- Email-->
  
            <b-card
            title="Email Us"
            img-src="../assets/bCard.jpg"
            img-alt="email"
            img-top
            tag="article"
            style="max-width: 80%;"
            class="m-4 text-center"
          >
          <b-row no-gutters>
            <b-form-textarea
              id="emailSubject"
              placeholder="Enter subject..."
              rows="1"
              max-rows="1"
              >
            </b-form-textarea>
            <br>
            <br>
            <b-form-textarea
              id="emailText"
              placeholder="Enter message..."
              rows="3"
              max-rows="6"
              >
            </b-form-textarea>
            <b-button  v-on:click="updateEmailBody" :href="`mailto:rahsaanandhakeem@gmail.com?body=${emailText}%0D%0DEmail from Website&subject=${emailSubject}`">Submit</b-button>
         </b-row>
          </b-card>

        <br>
        <br>
        <br>

          <b-card
            title="Pricing"
            img-src="../assets/pricing_tab.jpg"
            img-alt="pricing"
            img-top
            tag="article"
            style="max-width: 80%;"
            class="m-4 text-center"
          >
          
            <b-card-text>Instrumentals/Film/Apps/Advertisement</b-card-text>
            <b-button href= "https://www.songtradr.com/user/profile/rahsaan.shareef" target="_blank">License My Music</b-button>
          </b-card>

          </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  name: 'Contact',

  data(){
    return{
      emailText : null,
      emailSubject : null 
    }
  },
  methods:{
    updateEmailBody(){
      this.emailText =  document.getElementById("emailText").value
      this.emailSubject =  document.getElementById("emailSubject").value

    }
  }

}
</script>

<style scoped>

 /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {

  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {

  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {

  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {

  }


</style>